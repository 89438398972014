export const en = {
    "Llamanos ahora": "Talk to us now",
    "Idioma": "Language",
    "Contacta con nosotros": "Contact us",
    "Nosotros": "About",
    "Servicios": "Services",
    "Catalogo": "Catalog",
    "Contacto": "Contact",
    "¿Preguntas?": "FAQ",
    "Buscar por criterios": "Search By Criteria",
    "Correo Electrónico": "Email",
    "Horario Laboral": "Operating Hours",
    "Teléfono": "Phone",
    "Oficina": "Office",
    "Cotiza con nosotros": "Quote with us",
    "Nombre": "Name",
    "Nombre De Empresa": "Company Name",
    "Comentario": "Comment",
    "Ejemplo": "Sample",
    "Someter": "Submit",
    "Móvil": "Mobile",
    "¿Cómo cotizar con nosotros?": "How to quote with us?",
    "De lunes a viernes": "Monday to friday",
    "Enviar arte editable, foto o muestra física con sus medidas, cantidades a cotizar, material, procesos especiales y demás. De no tener ninguno de los anteriores, Contactenos.": "Send editable art, photo or physical sample with its measurements, quoted quantities, material, special processes and others. If you don’t have any of this,  contact us.",
    "¿Cuáles son los métodos de envío que utilizamos?": "What are the shipping methods we use?",
    "UPS, FEDEX, Y DE DHL para envíos internacionales y transporte local.": "UPS, FEDEX, AND DHL for international shipping and local transportation.",
    "¿Cuál es el tiempo de entrega de una orden?": "What is the delivery time of an order?",
    "Entre 15 y 22 días para ordenes nuevas y entre 12 y 15 días para ordenes repetidas (tiempos sujetos a cambios).": "Between 15 and 22 days for new orders and between 12 and 15 days for reworks (times subject to change).",
    "¿Cuáles son las formas de pago?": "What are the payment options?",
    "Trasferencia bancaria local e internacional, tarjeta de crédito (Visa, Master Card, American Express), Pay pal, Western Uniòn. 50% antes de producción y 50% antes de la entrega.": "Local and international bank transfer, credit card (Visa, Master Card, American Express), Pay pal, Western Union. 50% before production and 50% before delivery.",
    "¿Cuáles tipos de archivos o extensiones utilizamos?": "File types which we use?",
    "¿Qué se necesita para elaborar un producto con nosotros?": "What do you need to make a product with us?",
    "Un boceto, una idea, una imagen…": "A sketch, an idea, an image of what you want",
    "¿Cuál es el mínimo para ordenar?": "What is the minimum to order?",
    "Esto dependerá del producto que usted requiera.": "This will depend on the product that you require.",
    "¿Cómo Puedo saber si mis productos han sido despachados?": "How can I know if my products have been shipped?",
    "Si es local se le notificara al despachar y si es internacional se le enviara el número de tracking una vez despachada la orden.": "If it is local, it will be notified and if it is international, the tracking number will be sent to you once the order has been dispatched.",
    "No encuentro la respuesta a mi pregunta ¿Qué hago?": "I can't find the answer to my question. What do I do?",
    "Contáctanos y déjanos saber cómo podemos ayudarte.": "Go to contact and let us know how we can help you.",
    "!Tus productos lo merecen": "Your products deserve it",
    // "Somos una empresa con sede en el Parque Industrial de la Zona Franca en Santiago, en la República Dominicana. Nuestra empresa ofrece servicios de impresión para el mercado local e internacional, estamos orientados a esforzarnos siempre y superar la satisfacción de nuestros clientes. A través de nuestro arduo trabajo, nos hemos convertido en una de las compañías líderes en impresión, ayudando a la industria con excelentes soluciones de embalaje.": "We are a company based in the Free Zone Industrial Park in Santiago, in the Dominican Republic. Our Company is a decorating and printing services for local and international market, we are oriented to always strive and exceed our customer satisfaction. Through our hard work, we have become one of the leading companies in the decorating, helping the industry with excellent packing solutions.",
    "Somos una empresa con sede en el Parque Industrial de la Zona Franca en Santiago, en la República Dominicana. Nuestra empresa ofrece servicios de impresión para el mercado local e internacional, estamos orientados a esforzarnos siempre y superar la satisfacción de nuestros clientes. A través de nuestro arduo trabajo, nos hemos convertido en una de las compañías líderes en impresión, ayudando a la industria con excelentes soluciones de embalaje.": "We offer multitude of printing options for our customers, impression techniques like Flexography, Offset Screen Printing and wrapped boxes as well, we print from a single-color to a fully customized one, our workflow process has been engineered for unrivaled production times without sacrificing quality.",
    // "Desde 2008 hemos prestado servicios a empresas en Santiago, República Dominicana, en algunos principios simples: suministrar productos de calidad a un precio justo y entregarlos a tiempo.": "Since 2008 has been servicing decoration businesses in Santiago Dominican Republic on some simple principles, Supply quality products at a fair price and delivering them on time.",
    "Desde 2008 hemos prestado servicios a empresas en Santiago, República Dominicana, en algunos principios simples: suministrar productos de calidad a un precio justo y entregarlos a tiempo.": "We are a graphics printing company stablished since 2008, located in Gurabo Industrial Park, Santiago Dominican Republic",
    // "Tratar a nuestros clientes y empleados como familia y convertirlos en nuestra prioridad cada día.": "Treat our customers and employees as family and make them our priority each and every day.",
    "Tratar a nuestros clientes y empleados como familia y convertirlos en nuestra prioridad cada día.": "We are integrated by a professional and experimental team to give to our customers the satisfaction to receive products with Quality printing services & fast turnaround that you can depend on",
    "Brindar el mejor servicio, calidad y valor posibles en la industria.": "Provide the best possible service, quality and value in the industry.",
    // "Nuestra dedicación al excelente servicio al cliente ha contribuido a hacernos la empresa que somos hoy en día.": "Our dedication to excellent customer services has contributed to make us the company we are today.",
    "Nuestra dedicación al excelente servicio al cliente ha contribuido a hacernos la empresa que somos hoy en día.": "Our dedication for the services, the business commitment and the contribution to the growth of our team are the points that made us the company we are.",
    "Alsack como solución": "Alsack as a solution",
    "Ayudamos a nuestros clientes a encontrar la solución más rentable. Nuestro único objetivo es establecer relaciones exitosas a largo plazo, por lo que tenemos el incentivo para ayudarlo a encontrar la mejor combinación de calidad, servicio y precio personalizando los detalles específicos de las necesidades comerciales de nuestros clientes. Nuestras experiencias de impresión nos han enseñado cómo entregar de manera eficiente y confiable los servicios y productos impresos a nuestros clientes ‘con los detalles exactos.": "We help our clients find the most cost effective solution. Our only goal is establishing successful and long term relationships so we have the incentive to help you find the best combination of quality, service and price by customizing the specifics of our clients' business 'needs. Our decorating experiences has taught us how to efficiently and dependably deliver printed garments and products to our clients 'width the exact details.",
    "Dirección": "Address",
    "Suscríbase a nuestro correo electrónico para recibir actualizaciones de nosotros": "Subscribe to our email to get updates from us",
    "Ingresa tu correo electrónico": "Enter your email",
    "Error interno, favor intentar mas tarde o contactar via correo electronico" : "Internal error, please try later or contact via email",
    "La purpurina es una impresión con polvo dorado que hará que sus anillos y etiquetas tengan ese toque perfecto que afirma que sus productos son de alta calidad": "The bronzing technique is an impression with gold powder that will give your rings and labels the perfect touch that claims your products are of high quality",
    "Las cajas forradas son la tendencia en cuanto a empaque se refiere, se imprime papel con todas las técnicas y luego se forra sobre MDF o cartón prensado. Sus empaques se sentirán fuertes y de alta calidad.": "Wrapped Box is the trend of packaging, we can make an impression on paper with all techniques and wrapped a MDF or pressed paperboard box. Your packaging will feel stronger and high quality.",
    "Republica Dominicana es el país mas famoso en la industria del tabaco, entendemos la importancia de un anillo exclusivo y con glamour, empoderando a AlsackLabels usted asegura una increíble presentación para sus productos.": "As we are located in the Dominican Republic and being the most famous country in the Cigar industry, we over stand the importance of exclusive and a glamorous Cigar and its Label Bands, by empowering Alsack Labels you ensure an awesome and interesting image for your products.",
    "El Hot stamping es una aplicación que resalta los elementos de sus anillos, etiquetas y empaques, dando un contraste ideal entre las impresiones. Ofrecemos una amplia gama de colores y tipos de foil para dar a sus productos la belleza que merecen.": "Hot Stamping is an application that highlights the elements of your rings, labels and packaging, giving an ideal contrast with the impression. We offer a wide range of colors and types o foils to give to your products the beauty they deserve.",
    "Las etiquetas no adhesivas están abiertas a una gran gama de técnicas y aplicaciones para hacer que sus productos tengan un empaque que muestre la calidad del mismo.": "The non-adhesive labels are open to a wide range of techniques and applications to make your products impacts with a packaging with quality.",
    "Disponemos de magnesio para uso en diferentes industrias. Nuestro proceso de grabado se puede ajustar para adaptarse a al diseño y la profundidad que necesite su aplicación.": "We carry a magnesium for uses in many industries. Our etching tanks can be adjusted to match the design and depth you need based on your application.",
    "Nuestra Experiencia en decoración de prendas nos ha enseñador a entregar productos de manera eficiente y de acuerdo a las especificaciones de nuestros clientes con las especificaciones exactas, lo que nos asegura producir resultados de alta calidad.": "Our decorating experience has taught us how to efficiently and dependently deliver garments and products to our customer with the exact specifications assuring to produce a high printed quality result.",
    "Los clientes son muy selectivos con sus empaques. Para esto ofrecemos diferentes técnicas de impresión de barniz UV como son: Alto Brillo, Mate, Efecto Arena y Alto Relieve para provocar una sensación visual y al tacto de alta calidad a sus productos.": "Customer are very selective with their packaging. We offer a different techniques of UV barniz printing like: Hight Gloss, Matte, Sand Effects and Emboss Effects for made a visual and touch sense of high quality on your products.",
    "Las cajas plegadizas son una opción ideal para el empaque de productos, disponibles en la formas y tamaños que se adapten a la necesidad de nuestros clientes.": "The folding boxes are a packaging option to all your products, available in a shapes and sizes that our customer need.",
    "Anterior": "Previous",
    "Próximo": "Next",
    "Ayudamos a nuestros clientes a encontrar la solución más rentable. Nuestro único objetivo es establecer relaciones exitosas a largo plazo, por lo que tenemos el incentivo para ayudarlo a encontrar la mejor combinación de calidad, servicio y precio personalizando, los detalles específicos de las necesidades comerciales de nuestros clientes. Nuestras experiencias de impresión nos han enseñado cómo entregar de manera eficiente y confiable los servicios y productos impresos a nuestros clientes con los detalles exactos.": "We help our clients find the most cost effective solution. Our only goal is establishing successful and long term relationships so we have the incentive to help you find the best combination of quality, service and price by customizing the specifics of our clients business needs. Our decorating experiences has taught us how to efficiently and dependably deliver printed graphics and products to our clients with the exact required details.",
    "Nos alegra su visita": "We are pleased for your visit",
    "Archivo": "File",
    "Enviar": "Send",
    "Cargar un archivo": "Upload a file",
    "Cada año producimos millones de etiquetas adhesivas para diferentes industrias, labels en rollos en diferentes tamaños, formas y técnicas de impresión son una solución ideal para aplicarlos en los empaques de sus proyectos.": "Every year, we produce millions of roll labels for a variety of industries. Roll labels can work with many label sizes, shapes, and printing techniques making an ideal labeling solution for many different projects."
}