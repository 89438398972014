import React, {useState} from 'react';
import {Row,Col} from 'react-bootstrap';
import {translate} from '../vendor/translator';
const Faq = () => {
  const [question1] = useState(translate("¿Cómo cotizar con nosotros?"));
  const [answer1] = useState(translate("Enviar arte editable, foto o muestra física con sus medidas, cantidades a cotizar, material, procesos especiales y demás. De no tener ninguno de los anteriores, Contactenos."));
  const [question2] = useState(translate("¿Cuáles son los métodos de envío que utilizamos?"));
  const [answer2] = useState(translate("UPS, FEDEX, Y DE DHL para envíos internacionales y transporte local."));
  const [question3] = useState(translate("¿Cuál es el tiempo de entrega de una orden?"));
  const [answer3] = useState(translate("Entre 15 y 22 días para ordenes nuevas y entre 12 y 15 días para ordenes repetidas (tiempos sujetos a cambios)."));
  const [question4] = useState(translate("¿Cuáles son las formas de pago?"));
  const [answer4] = useState(translate("Trasferencia bancaria local e internacional, tarjeta de crédito (Visa, Master Card, American Express), Pay pal, Western Uniòn. 50% antes de producción y 50% antes de la entrega."));
  const [question5] = useState(translate("¿Cuáles tipos de archivos o extensiones utilizamos?"));
  const [question6] = useState(translate("¿Qué se necesita para elaborar un producto con nosotros?"));
  const [answer6] = useState(translate("Un boceto, una idea, una imagen…"));
  const [question7] = useState(translate("¿Cuál es el mínimo para ordenar?"));
  const [answer7] = useState(translate("Esto dependerá del producto que usted requiera."));
  const [question8] = useState(translate("¿Cómo Puedo saber si mis productos han sido despachados?"));
  const [answer8] = useState(translate("Si es local se le notificara al despachar y si es internacional se le enviara el número de tracking una vez despachada la orden."));
  const [question9] = useState(translate("No encuentro la respuesta a mi pregunta ¿Qué hago?"));
  const [answer9] = useState(translate("Contáctanos y déjanos saber cómo podemos ayudarte."));
  return (
    <Row style={{marginBottom: 50}}>
      <Col md={12} className="removeSidePaddingContent">
        <div className="textBodyColor textBody">
          <h3>{question1}</h3>
          <p>
            {answer1}
          </p>
          <h3>{question2}</h3>
          <p>
            {answer2}
          </p>
          <h3>{question3}</h3>
          <p>
            {answer3}
          </p>
          <h3>{question4}</h3>
          <p>
            {answer4}
          </p>
          <h3>{question5}</h3>
          <p>
            Pdf,svg,ai,psd,eps
          </p>
          <h3>{question6}</h3>
          <p>
            {answer6}
          </p>
          <h3>{question7}</h3>
          <p>
            {answer7}
          </p>
          <h3>{question8}</h3>
          <p>
            {answer8}
          </p>
          <h3>{question9}</h3>
          <p>
            {answer9}
          </p>
        </div>
      </Col>
    </Row>
  );
}
export default Faq;