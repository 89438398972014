import React, { useState, useCallback } from 'react';
import {Row,Col,Container,Carousel} from 'react-bootstrap';
import {translate} from "../vendor/translator";
import ImageViewer from 'react-simple-image-viewer';
import slide1 from "../images/slides/1.jpg";
import slide2 from "../images/slides/2.jpg";
import slide3 from "../images/slides/3.jpg";
import slide4 from "../images/slides/4.jpg";
const Home = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [homeHeaderLabel] = useState(translate("Nos alegra su visita"));
  const [homeLabel] = useState(translate("Ayudamos a nuestros clientes a encontrar la solución más rentable. Nuestro único objetivo es establecer relaciones exitosas a largo plazo, por lo que tenemos el incentivo para ayudarlo a encontrar la mejor combinación de calidad, servicio y precio personalizando, los detalles específicos de las necesidades comerciales de nuestros clientes. Nuestras experiencias de impresión nos han enseñado cómo entregar de manera eficiente y confiable los servicios y productos impresos a nuestros clientes con los detalles exactos."));
  const images = [
    slide1,
    slide2,
    slide3,
    slide4
  ];
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <Container fluid>
      <Row>
        <Col md={{span: 7}}>
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={slide1}
                alt="First slide"
                onClick={ () => openImageViewer(0) }
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={slide2}
                alt="Third slide"
                onClick={ () => openImageViewer(1) }
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={slide3}
                alt="Third slide"
                onClick={ () => openImageViewer(2) }
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={slide4}
                alt="Third slide"
                onClick={ () => openImageViewer(3) }
              />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md={{span: 5}}>
          <h3>{homeHeaderLabel}</h3>
          <p style={{textAlign: "justify"}}>{homeLabel}</p>
        </Col>
      </Row>
      {/* <Row style={{marginTop: 15}}>
        <Col>
          <h3>{homeHeaderLabel}</h3>
          <p style={{textAlign: "justify"}}>{homeLabel}</p>
        </Col>
      </Row> */}
      {isViewerOpen && (
        <ImageViewer
          src={ images }
          currentIndex={ currentImage }
          onClose={ closeImageViewer }
        />
      )}
    </Container>
  );
}
export default Home;