import React, { useState, useEffect } from 'react';
import {Row, Col, Image, Pagination, Button} from 'react-bootstrap';
/** local */
import {images} from '../data/catalogData';
/** local */
import {translate} from '../vendor/translator';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft,faArrowRight} from '@fortawesome/free-solid-svg-icons';
const Catalog = () => {
  const elements = 9;
  /** local */
  const lastElement = 45;
  /** local */
  // const [lastElement,setLastElement] = useState(0);
  // const [lastShowElement,setLastShowElement] = useState(0);
  /** local */
  // const [StartShowElement,setStartShowElement] = useState(0);
  /** local */
  const [fromIndex,setFromIndex] = useState(0);
  /** local */
  const [toIndex,setToIndex] = useState(elements);
  /** local */
  // const [toIndex,setToIndex] = useState(0);
  const [currentImages,setCurrentImages] = useState([]);
  const [beforeLabel] = useState(translate("Anterior"));
  const [nextLabel] = useState(translate("Próximo"));
  const [loading,setLoading] = useState(false);
  // useEffect(()=>{
  //   let imageList = [];
  //   fetch(
  //     process.env.REACT_APP_API_PATH + 'get/all/catalog/'+elements+'/'+toIndex+'/'+fromIndex,
  //     {
  //       method: "GET"
  //     }
  //   )
  //     .then(response=>response.json())
  //     .then(data=>{
  //       console.log("data",data);
  //       const imagesList = data.images;
  //       for(let i=0;i<imagesList.length;i++){
  //         imageList.push(imagesList[i].path);
  //       }
  //       setLoading(false);
  //       setCurrentImages(imageList);
  //       setLastElement(data.totalImage);
  //       setLastShowElement(data.lastImage);
  //       // setStartShowElement(data.startImage);
  //     })
  //     .catch(error=>{
  //       console.log("error", translate('Error interno, favor intentar mas tarde o contactar via correo electronico'));
  //     });
  // },[toIndex,fromIndex]);
  useEffect(()=>{
    let cImages = [];
    for(let index=0;index<images.length;index++){
      if(index===toIndex){
        break;
      }
      if(index>=fromIndex&&index<=toIndex) {
        cImages.push(images[index]);
      }
    }
    setCurrentImages(cImages);
  },[toIndex,fromIndex]);
  const thumbnail = () =>{
    let response = [],
      rows = [];
    for(let i=0;i<currentImages.length;i++){
      if(i>0&&i%3===0){
        response.push(
          <Row key={i}>
            {rows}
          </Row>
        );
        rows = [];
      }
      else
      {
        if(i===(currentImages.length-1)){
          response.push(
            <Row key={i}>
              {rows}
            </Row>
          );
        }
      }
      rows.push(
          <Col key={i} xs={12} md={4} style={{marginBottom: '15px'}}>
            <Image src={currentImages[i]} thumbnail width={584.328} height={584.328} />
          </Col>
        );
    }
    return response;
  }
  // const beforeElements = () => {
  //   setLoading(true);
  //   setFromIndex(lastShowElement);
  // }
  // const nextElements = () => {
  //   setLoading(true);
  //   setToIndex(lastShowElement);
  // }
  const beforeElements = () => {
    let tI = fromIndex,
      fI = tI - elements;
    setFromIndex(fI);
    setToIndex(tI);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }
  const nextElements = () => {
    let fI = toIndex,
      tI = fI + elements;
    setFromIndex(fI);
    setToIndex(tI);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }
  return (
    <>
      {
        loading ?
          <Row className="justify-content-md-center">
            <Col md={1} style={{textAlign: "center",marginTop: 30,marginBottom: 30}}>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </Col>
          </Row>
        : thumbnail()
      }
      <Pagination>
        {/*{*/}
        {/*  toIndex > elements ? <Button style={{marginRight: 15}} variant="danger" onClick={beforeElements}>{beforeLabel}</Button>: null*/}
        {/*}*/}
        {
          fromIndex > 0 ? <Button style={{marginRight: 15}} variant="danger" onClick={beforeElements}><FontAwesomeIcon icon={faArrowLeft} /> {beforeLabel}</Button>: null
        }
        {
          toIndex < lastElement ? <Button variant="primary" onClick={nextElements}>{nextLabel} <FontAwesomeIcon icon={faArrowRight} /></Button>: null
        }
      </Pagination>
    </>
  );
}
export default Catalog;