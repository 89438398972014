import React,{useState} from 'react';
import {Container,Row,Col,Navbar,FormControl,InputGroup,Image,Button,Nav} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope,faHome} from '@fortawesome/free-solid-svg-icons';
import {translate,getLanguage} from '../vendor/translator';
import {data} from '../data/enterprise';
const Footer = () => {
  const [phoneNumber] = useState(data.PHONE);
  const [address] = useState(data.ADDRESS);
  const [email] = useState(data.EMAIL);
  const [emailLabel] = useState(translate("Correo Electrónico"));
  const [addressLabel] = useState(translate("Dirección"));
  const [operatingHoursLabel] = useState(translate("Horario Laboral"));
  const [phone] = useState(translate("Teléfono"));
  const [office] = useState(translate("Oficina"));
  const [days] = useState(translate("De lunes a viernes"));
  const [subscribeLabel] = useState(translate("Suscríbase a nuestro correo electrónico para recibir actualizaciones de nosotros"));
  const [enterEmail] = useState(translate("Ingresa tu correo electrónico"));
  const [about] = useState(translate("Nosotros"));
  const [services] = useState(translate("Servicios"));
  const [catalog] = useState(translate("Catalogo"));
  const [contact] = useState(translate("Contacto"));
  const [faq] = useState(translate("¿Preguntas?"));
  const [isLoading,setIsLoading] = useState(false);
  const [newsletterEmail,setNewsLetterEmail] = useState("");
  const newsletter = (emailValue) => {
    const email = emailValue.length ? emailValue : newsletterEmail;
    if ( email.length < 1 ) {
      alert(translate("Ingresa tu correo electrónico"));
      return false;
    }
    let data = new FormData();
    data.append("email", email);
    data.append("LANG", getLanguage());
    setIsLoading(true);
    fetch(
      process.env.REACT_APP_API_PATH + 'save/newsletter',
      {
        method: "POST",
        body: data
      }
    )
      .then(response=>response.json())
      .then(data=>{
        if ( !data.error ) {
          //success
          alert(data.message);
          setIsLoading(false);
        } else {
          //warning
          alert(data.message);
          setIsLoading(false);
        }
      })
      .catch(error=>{
        alert(translate("Error interno, favor intentar mas tarde o contactar via correo electronico"));
        setIsLoading(false);
      });
  }
  const setEmail = (event) => {
    const value = event.target.value;
    setNewsLetterEmail(value);
  }
  const customPrompt = () => {
    const email = prompt(subscribeLabel, "");
    if (email != null) {
      newsletter(email)
    }
  }
  return (
    <Navbar fixed="bottom" className="mainBgColor mainColor footerBarRP">
      <Container fluid>
        <Row style={{width: '100%'}} className="hideOnSmallerScreen">
          <Col md={{span: 3, offset: 2}} className="fontSize14">
            <label className="textSecondaryColor">{addressLabel}</label>
            <p>
              {address}
            </p>
          </Col>
          <Col md={{span: 2}} className="fontSize14" style={{marginTop: '15px'}}>
            <label className="textSecondaryColor">{operatingHoursLabel}</label>
            <p>{days}: {data.OPERATING}</p>
          </Col>
          <Col md={{span: 3, offset: 2}} className="d-flex justify-content-end">
            <Button variant="link" className="separateSocialNetwork">
              <Image src={process.env.PUBLIC_URL + 'icons/facebook2.png'} onClick={()=>window.open("https://web.facebook.com/Alsack-Labels-110984733996068/?modal=admin_todo_tour",'_blank')} className="icon iconSeparation" />
            </Button>
            <Button variant="link" onClick={()=>window.open("https://www.instagram.com/alsacklabel/",'_blank')} className="separateSocialNetwork">
              <Image src={process.env.PUBLIC_URL + 'icons/instagram2.png'} className="icon iconSeparation" />
            </Button>
            <Button variant="link" onClick={()=>window.open("https://www.linkedin.com/in/alsack-labels-1072aa177/",'_blank')} className="separateSocialNetwork">
              <Image src={process.env.PUBLIC_URL + 'icons/linkedin2.png'} className="icon iconSeparation" />
            </Button>
            <Button variant="link" onClick={()=>window.open("https://twitter.com/AlsackL",'_blank')} className="separateSocialNetwork">
              <Image src={process.env.PUBLIC_URL + 'icons/twitter2.png'} className="icon iconSeparation" />
            </Button>
            <Button variant="link" onClick={()=>window.open("https://pin.it/6YalCht",'_blank')} className="separateSocialNetwork">
              <Image src={process.env.PUBLIC_URL + 'icons/printerest.png'} className="icon iconSeparation" />
            </Button>
          </Col>
          <Col md={{span: 2}} className="footHomeIcon">
            <a href="/" className="textSecondaryColor">
              <FontAwesomeIcon icon={faHome} />
            </a>
          </Col>
          <Col md={{span: 2}} className="fontSize14" style={{marginTop: '15px'}}>
            <label className="textSecondaryColor">{emailLabel}</label>
            <p>{email}</p>
          </Col>
          <Col md={{span: 2, offset: 1}} className="fontSize14">
          
            <label className="textSecondaryColor">{phone}</label>
            <p>
              {office}: {phoneNumber}
            </p>
        
          </Col>
          <Col md={1} style={{marginTop: -84}}>
            <Nav className="flex-column">
              <Nav.Link href="/about" className="navFooter">{about}</Nav.Link>
              <Nav.Link href="/services" className="navFooter">{services}</Nav.Link>
              <Nav.Link href="/catalog" className="navFooter">{catalog}</Nav.Link>
              <Nav.Link href="/contact" className="navFooter">{contact}</Nav.Link>
              <Nav.Link href="/faq" className="navFooter">{faq}</Nav.Link>
            </Nav>
          </Col>
          <Col md={{span: 3, offset: 1}} style={{textAlign: 'center'}}>
            <label style={{fontSize: '14px'}}>{subscribeLabel}</label>
            <InputGroup className="mb-3 d-flex justify-content-center">
              <InputGroup.Append>
                <InputGroup.Text className="btn btn-info" onClick={newsletter}><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text>
              </InputGroup.Append>
              <FormControl
                id="newsletterEmail"
                placeholder={enterEmail}
                onChange={setEmail}
                disabled={isLoading ? true : false}
              />
            </InputGroup>
          </Col>
          <Col md={12}>
            <p className="footPageText">COPYRIGHT 2020 ALSACKLABELS. ALL RIGHTS RESERVED</p>
          </Col>
        </Row>
      
        {/*Foot Responsive*/}
        <Row style={{width: '100%', display: 'none'}} className="showOnSmallerScreen">
          <Col xs={{span: 12}} className="d-flex justify-content-center">
            <Button variant="link" className="separateSN">
              <Image src={process.env.PUBLIC_URL + 'icons/facebook2.png'} onClick={()=>window.open("https://web.facebook.com/Alsack-Labels-110984733996068/?modal=admin_todo_tour",'_blank')} className="iconRp" />
            </Button>
            <Button variant="link" onClick={()=>window.open("https://www.instagram.com/alsacklabel/",'_blank')} className="separateSN">
              <Image src={process.env.PUBLIC_URL + 'icons/instagram2.png'} className="iconRp" />
            </Button>
            <Button variant="link" onClick={()=>window.open("https://www.linkedin.com/in/alsack-labels-1072aa177/",'_blank')} className="separateSN">
              <Image src={process.env.PUBLIC_URL + 'icons/linkedin2.png'} className="iconRp" />
            </Button>
            <Button variant="link" onClick={()=>window.open("https://twitter.com/AlsackL",'_blank')} className="separateSN">
              <Image src={process.env.PUBLIC_URL + 'icons/twitter2.png'} className="iconRp" />
            </Button>
            <Button variant="link" onClick={()=>window.open("https://pin.it/6YalCht",'_blank')} className="separateSN">
              <Image src={process.env.PUBLIC_URL + 'icons/printerest.png'} className="iconRp" />
            </Button>
          </Col>
          <Row>
            <Col xs={{span: 2}} className="footHomeIconRp" style={{marginLeft: '15px'}}>
              <a href="/" className="textSecondaryColor">
                <FontAwesomeIcon icon={faHome} />
              </a>
            </Col>
            <Col xs={{span: 1, offset: 8}} className="footHomeIconRp">
              <FontAwesomeIcon icon={faEnvelope} className="textSecondaryColor" onClick={customPrompt} />
            </Col>
          </Row>
          <Col xs={{span: 12}} className="d-flex justify-content-center">
            <p className="footPageTextRp">COPYRIGHT 2020 ALSACKLABELS. ALL RIGHTS RESERVED</p>
          </Col>
        </Row>
    
      </Container>
    </Navbar>
  );
}
export default Footer;