import React, {useEffect, useState} from 'react';
import {Row, Col, Image} from 'react-bootstrap';
import {translate} from '../vendor/translator';
import about1 from '../images/aboutus/1.jpg';
import about2 from '../images/aboutus/2.jpg';
import about3 from '../images/aboutus/3.jpg';
const About = () => {
  const [header1] = useState(translate("!Tus productos lo merecen"));
  const [detail1] = useState(translate("Somos una empresa con sede en el Parque Industrial de la Zona Franca en Santiago, en la República Dominicana. Nuestra empresa ofrece servicios de impresión para el mercado local e internacional, estamos orientados a esforzarnos siempre y superar la satisfacción de nuestros clientes. A través de nuestro arduo trabajo, nos hemos convertido en una de las compañías líderes en impresión, ayudando a la industria con excelentes soluciones de embalaje."));
  const [detail2] = useState(translate("Desde 2008 hemos prestado servicios a empresas en Santiago, República Dominicana, en algunos principios simples: suministrar productos de calidad a un precio justo y entregarlos a tiempo."));
  const [detail3] = useState(translate("Tratar a nuestros clientes y empleados como familia y convertirlos en nuestra prioridad cada día."));
  const [detail4] = useState(translate("Brindar el mejor servicio, calidad y valor posibles en la industria."));
  const [detail5] = useState(translate("Nuestra dedicación al excelente servicio al cliente ha contribuido a hacernos la empresa que somos hoy en día."));
  const [aboutImages, setAboutImages] = useState([]);
  useEffect(()=>{
    let imageList = [];
    fetch(
      process.env.REACT_APP_API_PATH + 'get/about/images',
      {
        method: "GET"
      }
    )
      .then(response=>response.json())
      .then(data=>{
        const imagesList = data.images;
        for(let i=0;i<imagesList.length;i++){
          imageList.push(imagesList[i].path);
        }
        setAboutImages(imageList)
      })
      .catch(error=>{
        console.log("error", translate('Error interno, favor intentar mas tarde o contactar via correo electronico'));
      });
  },[]);
  return (
    <>
      <Row style={{marginBottom: '30px'}}>
        <Col md={12} className="removeSidePaddingContent">
          <div className="textBodyColor textBody">
            <h3 style={{marginBottom: 20}}>{header1}!</h3>
            <p>
              {detail1}
            </p>
            <p>
              {detail2}
            </p>
            <p>
              {detail3}
            </p>
            <p>
              {detail4}
            </p>
            <p>
              {detail5}
            </p>
          </div>
        </Col>
      </Row>
      <Row style={{marginBottom: 50}}>
        {/*{*/}
        {/*  aboutImages.map(function(path,index){*/}
        {/*    return <Col xs={12} md={4} style={{marginBottom:15}}>*/}
        {/*              <Image src={path} thumbnail key={index} />*/}
        {/*            </Col>*/}
        {/*  })*/}
        {/*}*/}
        <Col xs={12} md={4} style={{marginBottom:15}}>
          <Image src={about1} thumbnail />
        </Col>
        <Col xs={12} md={4} style={{marginBottom:15}}>
          <Image src={about2} thumbnail />
        </Col>
        <Col xs={12} md={4} style={{marginBottom:15}}>
          <Image src={about3} thumbnail />
        </Col>
      </Row>
    </>
  );
}
export default About;