import Home from "../views/home";
import About from "../views/about";
import Catalog from "../views/catalog";
import Service from "../views/service";
import Faq from "../views/faq";
import Contact from "../views/contact";
import Review from "../views/review";
export const routes = [
  {
    key: 'base',
    path: '/',
    component: Home
  },
  {
    key: 'home',
    path: '/home',
    component: Home
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/catalog',
    component: Catalog
  },
  {
    path: '/services',
    component: Service
  },
  {
    path: '/contact',
    component: Contact
  },
  {
    path: '/faq',
    component: Faq
  },
  {
    path: '/review-:key',
    component: Review
  }
]