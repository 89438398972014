import React from 'react';
import './App.css';
import {BrowserRouter as Router,Switch, Route} from 'react-router-dom';
import {routes} from './api/routes';
import {Container} from 'react-bootstrap';
import NotFound from "./views/NotFound";
import Header from "./layouts/header";
import Footer from "./layouts/footer";
function App() {
  const screenHeight = window.screen.height,
    screen = Math.round( parseInt(screenHeight) * 0.7 ),
    completeScreen = Math.round( screenHeight - screen )
  return (
    <>
      <Header />
      <Container fluid className="mainContainer" style={{paddingBottom: completeScreen}}>
        <Router>
          <Switch>
            {
              routes.map(function ({key, path, component},index){
                return <Route exact path={path} component={component} key={index} />
              })
            }
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Container>
      <Footer />
    </>
  );
}
export default App;
