import {translate} from "../vendor/translator";
import bron1 from "../images/reviews/bronzing/bron1.jpg";
import bron2 from "../images/reviews/bronzing/bron2.jpg";
import bron3 from "../images/reviews/bronzing/bron3.jpg";
import wrap1 from "../images/reviews/wrapped/wrap1.jpg";
import wrap2 from "../images/reviews/wrapped/wrap2.jpg";
import wrap3 from "../images/reviews/wrapped/wrap3.jpg";
import cigar1 from "../images/reviews/cigar/cigar1.jpg";
import cigar2 from "../images/reviews/cigar/cigar2.jpg";
import cigar3 from "../images/reviews/cigar/cigar3.jpg";
import hot1 from "../images/reviews/hot/hot1.jpg";
import hot2 from "../images/reviews/hot/hot2.jpg";
import hot3 from "../images/reviews/hot/hot3.jpg";
import adh1 from "../images/reviews/adh/adh1.jpg";
import adh2 from "../images/reviews/adh/adh2.jpg";
import adh3 from "../images/reviews/adh/adh3.jpg";
import non1 from "../images/reviews/non/non1.jpg";
import non2 from "../images/reviews/non/non2.jpg";
import non3 from "../images/reviews/non/non3.jpg";
import pre1 from "../images/reviews/prepress/pre1.jpg";
import pre2 from "../images/reviews/prepress/pre2.jpg";
import pre3 from "../images/reviews/prepress/pre3.jpg";
import sp1 from "../images/reviews/sp/sp1.jpg";
import sp2 from "../images/reviews/sp/sp2.jpg";
import sp3 from "../images/reviews/sp/sp3.jpg";
import uv1 from "../images/reviews/uv/uv1.jpg";
import uv2 from "../images/reviews/uv/uv2.jpg";
import uv3 from "../images/reviews/uv/uv3.jpg";
import fold1 from "../images/reviews/folding/fold1.jpg";
import fold2 from "../images/reviews/folding/fold2.jpg";
import fold3 from "../images/reviews/folding/fold3.jpg";
const content = [
  {
    key: "bronzing",
    title: "Bronzing",
    image1: bron1,
    image2: bron2,
    image3: bron3,
    text: translate("La purpurina es una impresión con polvo dorado que hará que sus anillos y etiquetas tengan ese toque perfecto que afirma que sus productos son de alta calidad")
  },
  {
    key: "wrapped",
    title: "Wrapped Boxes",
    image1: wrap1,
    image2: wrap2,
    image3: wrap3,
    text: translate("Las cajas forradas son la tendencia en cuanto a empaque se refiere, se imprime papel con todas las técnicas y luego se forra sobre MDF o cartón prensado. Sus empaques se sentirán fuertes y de alta calidad.")
  },
  {
    key: "cigar",
    title: "Cigar Bands",
    image1: cigar1,
    image2: cigar2,
    image3: cigar3,
    text: translate("Republica Dominicana es el país mas famoso en la industria del tabaco, entendemos la importancia de un anillo exclusivo y con glamour, empoderando a AlsackLabels usted asegura una increíble presentación para sus productos.")
  },
  {
    key: "hot",
    title: "Hot Stamping",
    image1: hot1,
    image2: hot2,
    image3: hot3,
    text: translate("El Hot stamping es una aplicación que resalta los elementos de sus anillos, etiquetas y empaques, dando un contraste ideal entre las impresiones. Ofrecemos una amplia gama de colores y tipos de foil para dar a sus productos la belleza que merecen.")
  },
  {
    key: "adh",
    title: "Adhesive Label",
    image1: adh1,
    image2: adh2,
    image3: adh3,
    text: translate("Cada año producimos millones de etiquetas adhesivas para diferentes industrias, labels en rollos en diferentes tamaños, formas y técnicas de impresión son una solución ideal para aplicarlos en los empaques de sus proyectos.")
  },
  {
    key: "non",
    title: "Non Adhesive Labels",
    image1: non1,
    image2: non2,
    image3: non3,
    text: translate("Las etiquetas no adhesivas están abiertas a una gran gama de técnicas y aplicaciones para hacer que sus productos tengan un empaque que muestre la calidad del mismo.")
  },
  {
    key: "prepress",
    title: "Pre-Press",
    image1: pre1,
    image2: pre2,
    image3: pre3,
    text: translate("Disponemos de magnesio para uso en diferentes industrias. Nuestro proceso de grabado se puede ajustar para adaptarse a al diseño y la profundidad que necesite su aplicación.")
  },
  {
    key: "sp",
    title: "Screen Printing",
    image1: sp1,
    image2: sp2,
    image3: sp3,
    text: translate("Nuestra Experiencia en decoración de prendas nos ha enseñador a entregar productos de manera eficiente y de acuerdo a las especificaciones de nuestros clientes con las especificaciones exactas, lo que nos asegura producir resultados de alta calidad.")
  },
  {
    key: "uv",
    title: "Spot UV",
    image1: uv1,
    image2: uv2,
    image3: uv3,
    text: translate("Los clientes son muy selectivos con sus empaques. Para esto ofrecemos diferentes técnicas de impresión de barniz UV como son: Alto Brillo, Mate, Efecto Arena y Alto Relieve para provocar una sensación visual y al tacto de alta calidad a sus productos.")
  },
  {
    key: "fold",
    title: "Folding Boxes",
    image1: fold1,
    image2: fold2,
    image3: fold3,
    text: translate("Las cajas plegadizas son una opción ideal para el empaque de productos, disponibles en la formas y tamaños que se adapten a la necesidad de nuestros clientes.")
  }
];
export const getReview = (key) => {
  for(let i=0;i<content.length;i++){
    const object = content[i];
    if(object.key===key){
      return object;
    }
  }
  return null;
}