import catalog1 from '../images/catalog/1.jpg';
import catalog2 from '../images/catalog/2.jpg';
import catalog3 from '../images/catalog/3.jpg';
import catalog4 from '../images/catalog/4.jpg';
import catalog5 from '../images/catalog/5.jpg';
import catalog6 from '../images/catalog/6.jpg';
import catalog7 from '../images/catalog/7.jpg';
import catalog8 from '../images/catalog/8.jpg';
import catalog9 from '../images/catalog/9.jpg';
import catalog10 from '../images/catalog/10.jpg';
import catalog11 from '../images/catalog/11.jpg';
import catalog12 from '../images/catalog/12.jpg';
import catalog13 from '../images/catalog/13.jpg';
import catalog14 from '../images/catalog/14.jpg';
import catalog15 from '../images/catalog/15.jpg';
import catalog16 from '../images/catalog/16.jpg';
import catalog17 from '../images/catalog/17.jpg';
import catalog18 from '../images/catalog/18.jpg';
import catalog19 from '../images/catalog/19.jpg';
import catalog20 from '../images/catalog/20.jpg';
import catalog21 from '../images/catalog/21.jpg';
import catalog22 from '../images/catalog/22.jpg';
import catalog23 from '../images/catalog/23.jpg';
import catalog24 from '../images/catalog/24.jpg';
import catalog25 from '../images/catalog/25.jpg';
import catalog26 from '../images/catalog/26.jpg';
import catalog27 from '../images/catalog/27.jpg';
import catalog28 from '../images/catalog/28.jpg';
import catalog29 from '../images/catalog/29.jpg';
import catalog30 from '../images/catalog/30.jpg';
import catalog31 from '../images/catalog/31.jpg';
import catalog32 from '../images/catalog/32.jpg';
import catalog33 from '../images/catalog/33.jpg';
import catalog34 from '../images/catalog/34.jpg';
import catalog35 from '../images/catalog/35.jpg';
import catalog36 from '../images/catalog/36.jpg';
import catalog37 from '../images/catalog/37.jpg';
import catalog38 from '../images/catalog/38.jpg';
import catalog39 from '../images/catalog/39.jpg';
import catalog40 from '../images/catalog/40.jpg';
import catalog41 from '../images/catalog/41.jpg';
import catalog42 from '../images/catalog/42.jpg';
export const images = [
  catalog1,
  catalog2,
  catalog3,
  catalog4,
  catalog5,
  catalog6,
  catalog7,
  catalog8,
  catalog9,
  catalog10,
  catalog11,
  catalog12,
  catalog13,
  catalog14,
  catalog15,
  catalog16,
  catalog17,
  catalog18,
  catalog19,
  catalog20,
  catalog21,
  catalog22,
  catalog23,
  catalog24,
  catalog25,
  catalog26,
  catalog27,
  catalog28,
  catalog29,
  catalog30,
  catalog31,
  catalog32,
  catalog33,
  catalog34,
  catalog35,
  catalog36,
  catalog37,
  catalog38,
  catalog39,
  catalog40,
  catalog41,
  catalog42,
];