import React, {useEffect, useState, useCallback} from 'react';
import {Row,Col,Image} from 'react-bootstrap';
import {getReview} from '../data/reviewData';
import ImageViewer from 'react-simple-image-viewer';
// import {translate} from "../vendor/translator";
const Review = (props) => {
  const [key] = useState(props.match.params.key);
  const [review,setReview] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images,setImage] = useState([]);
  // const [reviewImages, setReviewImages] = useState([]);
  useEffect(()=>{
    const obj = getReview(key);
    const i = [
      obj.image1,
      obj.image2,
      obj.image3
    ];
    setImage(i);
    setReview(obj);
  },[key]);
  // useEffect(()=>{
  //   let imageList = [];
  //   fetch(
  //     process.env.REACT_APP_API_PATH + 'get/service/images/'+key,
  //     {
  //       method: "GET"
  //     }
  //   )
  //     .then(response=>response.json())
  //     .then(data=>{
  //       const imagesList = data.images;
  //       for(let i=0;i<imagesList.length;i++){
  //         imageList.push(imagesList[i].path);
  //       }
  //       setReviewImages(imageList)
  //
  //       const obj = getReview(key);
  //       setImage(imageList);
  //       setReview(obj);
  //
  //     })
  //     .catch(error=>{
  //       console.log("error", translate('Error interno, favor intentar mas tarde o contactar via correo electronico'));
  //     });
  // },[]);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <>
      {
        review!==null?
        <>
          <Row>
            <Col md={12} className="textBodyColor textBody">
              <h3>{review.title}</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="textBodyColor textBody">
              <p>{review.text}</p>
            </Col>
          </Row>
          <Row style={{marginBottom: 50}}>
            {/*{*/}
            {/*  reviewImages.map(function(path,index){*/}
            {/*    return <Col xs={12} md={4} style={{marginBottom:15}}>*/}
            {/*      <Image src={path} thumbnail onClick={ () => openImageViewer(index) } style={{cursor: 'pointer'}} />*/}
            {/*    </Col>*/}
            {/*  })*/}
            {/*}*/}
            <Col xs={12} md={4} style={{marginBottom:15}}>
              <Image src={review.image1} thumbnail onClick={ () => openImageViewer(0) } style={{cursor: 'pointer'}} />
            </Col>
            <Col xs={12} md={4} style={{marginBottom:15}}>
              <Image src={review.image2} thumbnail onClick={ () => openImageViewer(1) } style={{cursor: 'pointer'}} />
            </Col>
            <Col xs={12} md={4} style={{marginBottom:15}}>
              <Image src={review.image3} thumbnail onClick={ () => openImageViewer(2) } style={{cursor: 'pointer'}} />
            </Col>
          </Row>
        </>
          : null
      }
      {isViewerOpen && (
        <ImageViewer
          src={ images }
          currentIndex={ currentImage }
          onClose={ closeImageViewer }
        />
      )}
    </>
  );
}
export default Review;