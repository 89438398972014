import React, {useState} from 'react';
import {Row,Col,Container,Form,Button,Spinner,Alert,Toast} from 'react-bootstrap';
import {translate,getLanguage} from '../vendor/translator';
import {data} from '../data/enterprise';
import Map from '../vendor/map';
const Contact = () => {
  const [params, setParams] = useState({});
  const [file,setFile] = useState(null);
  const [fileName,setFileName] = useState("");
  const [headerLabel] = useState(translate("Cotiza con nosotros"));
  const [nameLabel] = useState(translate("Nombre"));
  const [emailLabel] = useState(translate("Correo Electrónico"));
  const [companyNameLabel] = useState(translate("Nombre De Empresa"));
  const [commentLabel] = useState(translate("Comentario"));
  const [SampleLabel] = useState(translate("Cargar un archivo"));
  const [SubmitLabel] = useState(translate("Enviar"));
  const [operatingHoursLabel] = useState(translate("Horario Laboral"));
  const [phone] = useState(translate("Teléfono"));
  const [office] = useState(translate("Oficina"));
  const [days] = useState(translate("De lunes a viernes"));
  const [isLoading,setIsLoading] = useState(false);
  const [toast,setToast] = useState(false);
  const [refresh,setRefresh] = useState(false);
  const [toastMessage,setToastMessage] = useState(null);
  const [error,setError] = useState(false);
  const setValues = (field) => (event) => {
    setParams({
      ...params,
      [field]: event.target.value
    });
  }
  const setFileResource = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    setFile(file);
  }
  const save = () => {
    const name = params['name'] !== undefined ? params.name : '';
    const email = params['email'] !== undefined ? params.email : '';
    const company = params['company'] !== undefined ? params.company : '';
    const comment = params['comment'] !== undefined ? params.comment : '';
    let data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("company", company);
    data.append("comment", comment);
    data.append("file", file);
    data.append("LANG", getLanguage());
    setIsLoading(true);
    fetch(
      process.env.REACT_APP_API_PATH + 'save/contact',
      {
        method: "POST",
        body: data
      }
    )
      .then(response=>response.json())
      .then(data=>{
        if ( !data.error ) {
          //success
          setIsLoading(false);
          setToast(true);
          setError(false);
          setToastMessage(<Alert variant="success">{data.message}</Alert>);
        } else {
          //warning
          setIsLoading(false);
          setToast(true);
          setError(true);
          setToastMessage(<Alert variant="warning">{data.message}</Alert>);
        }
      })
      .catch(error=>{
        setIsLoading(false);
        setToast(true);
        setError(true);
        setToastMessage(<Alert variant="danger">{translate('Error interno, favor intentar mas tarde o contactar via correo electronico')}</Alert>);
      });
  }
  const refreshToast = () => {
    setToast(false);
    setRefresh(true);
  }
  if ( refresh && error === false ) {
    window.location.reload();
  } else {
    return (
      <Container>
        <Row>
          <Col>
            <h1 className="textSecondaryColor">{headerLabel}!</h1>
          </Col>
        </Row>
        <Row style={{marginBottom: 50}}>
          <Col xs={12} md={6}>
            <Form>
              <Form.Group>
                <Form.Label className="mainTextColor">{nameLabel}</Form.Label>
                <Form.Control type="text" id="name" onChange={setValues('name')} />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mainTextColor">{emailLabel}</Form.Label>
                <Form.Control type="email" id="email" onChange={setValues('email')} />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mainTextColor">{companyNameLabel}</Form.Label>
                <Form.Control type="text" id="company" onChange={setValues('company')} />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mainTextColor">{commentLabel}</Form.Label>
                <textarea id="comment" className="form-control" rows={5} onChange={setValues('comment')}></textarea>
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="file" className="mainTextColor custom-file-upload">{SampleLabel}</Form.Label>&nbsp;&nbsp;<span>{fileName}</span>
                <Form.Control type="file" id="file" className="form-control" onChange={setFileResource}></Form.Control>
              </Form.Group>
              <Form.Group>
                {
                  isLoading ?
                    <Button variant="primary" disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </Button>
                    :
                    <Button variant="warning" onClick={save}>{SubmitLabel}</Button>
                }
              </Form.Group>
            </Form>
            <Row>
              <Col>
                <Toast onClose={refreshToast} show={toast} delay={3000} autohide>
                  <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <p className="textSecondaryColor">{emailLabel}</p>
            <p className="mainTextColor">{data.EMAIL}</p>
            <p className="textSecondaryColor">{operatingHoursLabel}</p>
            <p className="mainTextColor">{days}: {data.OPERATING}</p>
            <p className="textSecondaryColor">{phone}</p>
            <p className="mainTextColor">{office}: {data.PHONE}</p>
            <Map  />
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Contact;