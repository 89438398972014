import React from 'react';
import {Row,Col,Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import wrapped_box from '../images/pages/wrapped_box.jpg';
import bronzing from '../images/pages/bronzing.jpg';
import cigar_bands from '../images/pages/cigar_bands.jpg';
import folding_boxes from '../images/pages/folding_boxes.jpg';
import hot_stamping from '../images/pages/hot_stamping.jpg';
import adhesive_labels from '../images/pages/adhesive_labels.jpg';
import non_adhesive_labels from '../images/pages/non_adhesive_labels.jpg';
import pre_press from '../images/pages/pre_press.jpg';
import screen_printing from '../images/pages/screen_printing.jpg';
import uv_printing from '../images/pages/uv_printing.jpg';
const Service = () => {
  return(
    <>
      <Row>
        <Col xs={12} md={4} style={{marginBottom:15}}>
          <Card>
            <Link to="/review-wrapped">
              <Card.Img variant="top" src={wrapped_box} />
            </Link>
          </Card>
        </Col>
        <Col xs={12} md={4} style={{marginBottom:15}}>
          <Card>
            <Link to="/review-bronzing">
              <Card.Img variant="top" src={bronzing} />
            </Link>
          </Card>
        </Col>
        <Col xs={12} md={4} style={{marginBottom:15}}>
          <Card>
            <Link to="/review-cigar">
              <Card.Img variant="top" src={cigar_bands} />
            </Link>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4} style={{marginBottom:15}}>
          <Card>
            <Link to="/review-fold">
              <Card.Img variant="top" src={folding_boxes} />
            </Link>
          </Card>
        </Col>
        <Col xs={12} md={4} style={{marginBottom:15}}>
          <Card>
            <Link to="/review-hot">
              <Card.Img variant="top" src={hot_stamping} />
            </Link>
          </Card>
        </Col>
        <Col xs={12} md={4} style={{marginBottom:15}}>
          <Card>
            <Link to="/review-adh">
              <Card.Img variant="top" src={adhesive_labels} />
            </Link>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4} style={{marginBottom:15}}>
          <Card>
            <Link to="/review-non">
              <Card.Img variant="top" src={non_adhesive_labels} />
            </Link>
          </Card>
        </Col>
        <Col xs={12} md={4} style={{marginBottom:15}}>
          <Card>
            <Link to="/review-prepress">
              <Card.Img variant="top" src={pre_press} />
            </Link>
          </Card>
        </Col>
        <Col xs={12} md={4} style={{marginBottom:15}}>
          <Card>
            <Link to="/review-sp">
              <Card.Img variant="top" src={screen_printing} />
            </Link>
          </Card>
        </Col>        
      </Row>
      <Row>
        <Col xs={12} md={4} style={{marginBottom:15}}>
          <Card>
            <Link to="/review-uv">
              <Card.Img variant="top" src={uv_printing} />
            </Link>
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default Service;