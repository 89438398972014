import React, {useEffect,useState} from 'react';
import {Nav,Navbar,Button,Container,Row,Col,Image,DropdownButton,Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhoneAlt,faListAlt} from '@fortawesome/free-solid-svg-icons';
import {translate,setLanguage} from '../vendor/translator';
import {data} from '../data/enterprise';
const Header = () => {
  const [phoneNumber] = useState(data.PHONE);
  const [getInTouch] = useState(translate("Llamanos ahora"));
  const [language] = useState(translate("Idioma"));
  const [contactUs] = useState(translate("Contacta con nosotros"));
  const [about] = useState(translate("Nosotros"));
  const [services] = useState(translate("Servicios"));
  const [catalog] = useState(translate("Catalogo"));
  const [contact] = useState(translate("Contacto"));
  const [faq] = useState(translate("¿Preguntas?"));
  const [nav, setNav] = useState("");
  useEffect(()=>{
    const path = window.location.pathname === "/" ? "" : window.location.pathname
    setNav(path);
  },[]);
  const setLang = (lang) => {
    setLanguage(lang)
    window.location.reload()
  }
  return (
    <>
      <Navbar className="mainBgColor mainColor textHeader headerAlign" style={{paddingTop: '0', paddingBottom: '0'}} expand="lg">
        <Container fluid >
          <Row style={{width: '100%'}} className="hideOnSmallerScreen">
            <Col md={{span: 6}} className="d-flex justify-content-start">
              <label className="textSecondaryColor fontSize18" style={{marginLeft: '15px'}}>{getInTouch}! <a className="textSecondaryColor" href={`tel:${phoneNumber}`}>{phoneNumber}</a></label>
            </Col>
            <Col md={{span: 6}} className="d-flex justify-content-end">
              <label style={{padding: '7px',fontSize: '14px',margin: '0'}}>{language}</label>
              <span style={{padding: '7px',fontSize: '14px',margin: '0'}}>|</span>
              <Button variant="link" style={{padding: '0'}}>
                <Image src={process.env.PUBLIC_URL + 'dr.png'} className="iconHeader iconSeparationHead" onClick={()=>setLang('es')} />
              </Button>
              <span style={{padding: '7px',fontSize: '14px',margin: '0'}}>|</span>
              <Button variant="link" style={{padding: '0'}}>
                <Image src={process.env.PUBLIC_URL + 'eu.png'} className="iconHeader iconSeparationHead" onClick={()=>setLang('en')} />
              </Button>
              <span style={{padding: '7px',fontSize: '14px',margin: '0'}}>|</span>
              <Button variant="link" onClick={()=>window.location.href='/contact'} style={{paddingTop: '7px',paddingLeft: '0',paddingRight: '0',color: '#dadada',fontSize: '14px'}}>
                <FontAwesomeIcon icon={faPhoneAlt} /> {contactUs}
              </Button>
              <span style={{padding: '7px',fontSize: '14px',margin: '0'}}>|</span>
              <Button variant="link" style={{padding: '0'}}>
                <Image src={process.env.PUBLIC_URL + 'icons/facebook2.png'} onClick={()=>window.open("https://web.facebook.com/Alsack-Labels-110984733996068/?modal=admin_todo_tour",'_blank')} className="iconHeader iconSeparationHead" />
              </Button>
              <Button variant="link" onClick={()=>window.open("https://www.instagram.com/alsacklabel/",'_blank')} style={{padding: '0'}}>
                <Image src={process.env.PUBLIC_URL + 'icons/instagram2.png'} className="iconHeader iconSeparationHead" />
              </Button>
              <Button variant="link" onClick={()=>window.open("https://www.linkedin.com/in/alsack-labels-1072aa177/",'_blank')} style={{padding: '0'}}>
                <Image src={process.env.PUBLIC_URL + 'icons/linkedin2.png'} className="iconHeader iconSeparationHead" />
              </Button>
              <Button variant="link" onClick={()=>window.open("https://twitter.com/AlsackL",'_blank')} style={{padding: '0'}}>
                <Image src={process.env.PUBLIC_URL + 'icons/twitter2.png'} className="iconHeader iconSeparationHead" />
              </Button>
              <Button variant="link" onClick={()=>window.open("https://pin.it/6YalCht",'_blank')} style={{padding: '0'}}>
                <Image src={process.env.PUBLIC_URL + 'icons/printerest.png'} className="iconHeader iconSeparationHead" />
              </Button>
            </Col>
          </Row>
      
          {/*Foot Responsive*/}
          <Row style={{width: '100%', display: 'none'}} className="showOnSmallerScreen">
            <Col xs={{span: 12}} style={{paddingRight: '8px'}}>
              <Row>
                <Col xs={{span: 6}} className="d-flex justify-content-start">
                  <label className="textSecondaryColor fontSize10" style={{marginLeft: '15px',marginTop: '15px'}}>{getInTouch}! <br /><a className="textSecondaryColor" href={`tel:${phoneNumber}`}>{phoneNumber}</a></label>
                </Col>
                <Col xs={{span: 6}} style={{paddingRight: '0px'}} className="d-flex justify-content-end">
                  <Button variant="link" style={{padding: '0'}}>
                    <Image src={process.env.PUBLIC_URL + 'dr.png'} className="iconHeaderRp iconSeparationHead" onClick={()=>setLang('es')} />
                  </Button>
              
                  <Button variant="link" style={{padding: '0'}}>
                    <Image src={process.env.PUBLIC_URL + 'eu.png'} className="iconHeaderRp iconSeparationHead" onClick={()=>setLang('en')} />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
    
        </Container>
      </Navbar>
      <Navbar className="subHeaderColor textBodyColor headerAlign" expand="lg">
        <Container fluid>
          <Row style={{width: '100%'}} className="hideOnSmallerScreen">
            <Col md={{span: 6}}>
              <Navbar.Brand href="/" className="mainColor">
                <Image src={process.env.PUBLIC_URL + 'logo.png'} fluid className="logo" />
              </Navbar.Brand>
            </Col>
            <Col md={{span: 6}} className="d-flex justify-content-end" style={{paddingTop: 15}}>
              <Nav
                variant="pills"
                activeKey={nav}
                style={{paddingBottom: 20}}
              >
                <Nav.Item className="d-flex justify-content-end">
                  <Nav.Link href="/about" className="mainTextColor">{about}</Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-flex justify-content-end">
                  <Nav.Link href="/services" className="mainTextColor">{services}</Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-flex justify-content-end">
                  <Nav.Link href="/catalog" className="mainTextColor">{catalog}</Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-flex justify-content-end">
                  <Nav.Link href="/contact" className="mainTextColor">{contact}</Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-flex justify-content-end">
                  <Nav.Link href="/faq" className="mainTextColor">{faq}</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
      
          {/*Foot Responsive*/}
          <Row style={{width: '100%', display: 'none'}} className="showOnSmallerScreen">
            <Col xs={{span: 12}}>
              <Row>
                <Col xs={{span: 3}} className="d-flex justify-content-start iconListRp">
                  <DropdownButton
                    // as={ButtonGroup}
                    key="right"
                    id={`dropdown-button-drop-right`}
                    drop="right"
                    variant="secondary"
                    title={<FontAwesomeIcon icon={faListAlt} className="fontSize22" />}
                  >
                    <Dropdown.Divider />
                    <Dropdown.Item href="/about">{about}</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="/services">{services}</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="/catalog">{catalog}</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="/contact">{contact}</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="/faq">{faq}</Dropdown.Item>
                  </DropdownButton>
                </Col>
                <Col xs={{span: 7}} className="d-flex justify-content-center">
                  <Navbar.Brand href="/" className="mainColor">
                    <Image src={process.env.PUBLIC_URL + 'logo.png'} fluid className="logo" />
                  </Navbar.Brand>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </>
  );
}
export default Header;