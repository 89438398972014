import {
    en
} from "./languages/en";

//init
let lang = ''

export function translate(text){
    if ( lang.length < 1 )
        readLanguage()

    let textTranslated = "";

    switch (lang) {
        case "en":
            textTranslated =  en[text]
            break
        default:
            textTranslated = text
            break
    }
    return textTranslated
}

export function setLanguage(lang){
    sessionStorage.setItem("AlLang", lang)
}

export function getLanguage(){
    return lang
}

function readLanguage( ) {
    lang = sessionStorage.getItem("AlLang")
    if ( lang !== 'en' && lang !== 'es' ) {
        //default
        lang = 'en'
    }
}